import React, { Fragment } from 'react';

import { grid, titleText, subtitleText, contentText, button, list, listItem } from './faq.module.scss';
import { ISection } from '../../models/section.model';
import { IFaqCategory } from '../../models/faq.model';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';

import SingleFaq from '../molecules/single-faq';
import Section from '../hoc/section';
import {slugify} from "../../utils/slugify";

export interface IFaq extends ISection {
    items: {
        faqCategories: IFaqCategory[];
    };
}

interface IFaqProps {
    className?: string;
    section: IFaq;
    TitleTag?: React.ElementType;
}

const Faq: React.FC<IFaqProps> = ({ className = '', section, TitleTag = 'h2' }) => {
    const {
        content: { texts },
        style,
        items: { faqCategories },
        css,
        sectionId
    } = section;
    const QuestionTag = getLessImportantHeading(TitleTag);

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
                button: button,
            }}
            TitleTag={TitleTag}
            title={texts[0]}
            subtitle={texts[1]}
            description={texts[2]}
            style={style}
            css={css}
            roundedSection={true}
        >
            {faqCategories.length > 0 && faqCategories.map((category) => {
                return (
                    <Fragment key={`faq-list-${category.categoryId}`}>
                        {category.faqs.length > 0 && (
                            <ul className={list}>
                                {category.faqs.map((faq) => {
                                    return (
                                        <li className={listItem} key={`faq-list-item-${faq.faqId}`} id={slugify(faq.question)}>
                                            <SingleFaq singleFaq={faq} QuestionTag={QuestionTag} />
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </Fragment>
                )
            })}
        </Section>
    );
};

export default Faq;
