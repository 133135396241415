import React, {useEffect, useState} from 'react';
import AnimateHeight, { Height } from 'react-animate-height';

import {
    container,
    open,
    header,
    questionText,
    button,
    icon,
    answerBox,
} from './single-faq.module.scss';
import { ISingleFaq } from '../../models/faq.model';
import Markdown from '../hoc/markdown';
import {isBrowser} from "../../utils/is-browser";
import {slugify} from "../../utils/slugify";

interface ISingleFaqProps {
    className?: string;
    QuestionTag?: React.ElementType;
    singleFaq: ISingleFaq;
}

const SingleFaq: React.FC<ISingleFaqProps> = ({
    className = '',
    singleFaq,
    QuestionTag = 'h2',
}) => {
    const { faqId, question, answer } = singleFaq;
    const [height, setHeight] = useState<Height>(0);

    const handleToggleAnswer = () => {
        setHeight((prevHeight) => (prevHeight ? 0 : 'auto'));
    };

    useEffect(() => {
        if(isBrowser()) {
            if(window.location.hash === `#${slugify(singleFaq.question)}`) {
                setHeight('auto');
            }
        }
    }, []);

    return (
        <div className={`${container} ${className} ${height ? open : ''}`}>
            <div className={header}>
                <QuestionTag className={questionText}>{question}</QuestionTag>
                <button
                    aria-expanded={!!height}
                    aria-controls={`faq-answer-${faqId}`}
                    className={button}
                    aria-label={height ? 'close' : 'expand'}
                    onClick={handleToggleAnswer}
                >
                    <span className={icon} />
                </button>
            </div>
            <AnimateHeight id={`faq-answer-${faqId}`} duration={500} height={height}>
                <Markdown className={answerBox}>{answer}</Markdown>
            </AnimateHeight>
        </div>
    );
};

export default SingleFaq;
